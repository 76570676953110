import ExhibitInfoCard from "../components/exhibit-info-card/exhibit-info-card.component";
import SearchBar from "../components/search-bar/search-bar.component";
import { useState } from "react";

const QA = (props) => {
  const [question, setQuestion] = useState(null);
  const [response, setResponse] = useState(null);
  const [updateMessage, setUpdateMessage] = useState("Provide a query first");
  const [hideLoader, setHideLoader] = useState(true);
  const [disableFindButton, setDisableFindButton] = useState(false);

  const getAnswers = async () => {
    setResponse(null);
    setHideLoader(false);
    setUpdateMessage("Searching for relevant answers (Waiting Time: 1-2 minutes)");
    setDisableFindButton(true);
    props.setLoadBrain(true);
    if (question) {
      const ml_response = await fetch(`${props.urlPath}/predict`, {
        method: "post",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question }),
      })
        .then((response) => response.json())
        .then((jsonRespons) => {
          setUpdateMessage("Query results:");
          setResponse([...jsonRespons.predictions]);
          setDisableFindButton(false);
          props.setLoadBrain(false);
        })
        .catch((error) => {
          setDisableFindButton(false);
          setUpdateMessage("Could not find any relevant answers");
          setHideLoader(true);
          props.setLoadBrain(false);
        });
    }
  };

  return (
    <div className="container px-40">
      <SearchBar
        setQuestion={setQuestion}
        getAnswers={getAnswers}
        disabled={disableFindButton}
      />
      <div className="mx-5 font-bold">{updateMessage}</div>
      {response
        ? response.map((info, idx) => (
            <ExhibitInfoCard key={idx.toString()} info={info} />
          ))
        : hideLoader
        ? null
        : Array.from([1]).map((t) => <ExhibitInfoCard key={t.toString()} />)}
    </div>
  );
};
export default QA;
