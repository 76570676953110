import UploadSvg from "../../assets/svg/upload";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const URL =
  process.env.NODE_ENV === "production"
    ? "https://docx-processor.bitstone.eu/uploadfile/"
    : " http://127.0.0.1:8000/uploadfile/"; // replace here with local dev localshost

const NLP_URL =
  process.env.NODE_ENV === "production"
    ? "https://nlp-server.bitstone.eu"
    : "https://nlp-server.bitstone.eu"; // replace here with local dev localshost

const UploadCard = (props) => {
  let history = useHistory();

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPressed, setUploadPressed] = useState(false);
  // const [EXHIBIT_API_URL, setEXHIBIT_API_URL] = useState("");
  // const [showExhibitUrlInput, setShowExihitUrlInput] = useState(false);
  const [showProcessFile, setShowProcessFile] = useState(false);
  const [processorJson, setProcessorJson] = useState(null);
  const [message, setMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [fakeProgress, setFakeProgress] = useState(0);
  const [disbableButton, setDisbableButton] = useState(false);
  const [findInformationBtnClicked, setFindInformationBtnClicked] =
    useState(false);
  const [socketClient, setSocketClient] = useState(null);
  const [interval, setInvervalFunc] = useState(null);

  useEffect(() => {
    if (props.socket) {
      setSocketClient(props.socket);
      props.socket.onmessage = (event) => {
        if (event.data === "started") {
          
        }
        if (event.data === "true") {
          clearInterval(interval);
          setInvervalFunc(null);
          setFakeProgress(100);
          setFindInformationBtnClicked(false);
          history.push("/qa");
        }
      };
      return () => {
        setSocketClient(null);
      };
    }
  }, [props.socket]);

  const fileSelectedHandles = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const fileUploadHandler = async () => {
    if (
      selectedFile.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      let fd = new FormData();
      fd.append("upload_file", selectedFile, selectedFile.name);
      // console.log(selectedFile);
      setAlertColor("green");
      setUploadPressed(true);

      setMessage("Uploading file");

      const response = await fetch(URL, {
        method: "post",
        mode: "cors",
        body: fd,
      });

      setMessage("File uploaded");

      const responseJson = await response.json();

      setProcessorJson(responseJson);
      // setShowExihitUrlInput(true);
      setShowProcessFile(true);
    } else {
      setUploadPressed(true);
      setAlertColor("red");
      setMessage("Only .docx files are allowed");
    }
  };

  const uploadObjectToMachineLearningAPI = async () => {
    props.setExhibitUrlPath(NLP_URL);
    setFindInformationBtnClicked(true);
    setDisbableButton(true);
    let current_progress = 1;

    setAlertColor("green");
    setMessage(`Parsing  ${selectedFile.name} for querying`);
    const ml_response = await fetch(`${NLP_URL}/upload-exhibit-json`, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ exhibit_array: processorJson }),
    }).then((response) => {
      if (response.status === 200) {
        setMessage(`Preparing ${selectedFile.name} for querying`);
        // send init message
        try {

          //start fake progress
          let progress = 0;
          const interval = setInterval(() => {
            progress += 0.11;
            setFakeProgress(progress);
          }, 1000);
          setInvervalFunc(interval);
          //end fake progress

          socketClient.send("init");
          setAlertColor("blue");
        } catch (error) {
          setFindInformationBtnClicked(false);
          setMessage("Could not procced forward, please refresh webpage 😟");
          setAlertColor("red");
        }
      } else if (response.status === 404) {
        setFindInformationBtnClicked(false);
        setMessage("Could not procced forward 😟");
        setAlertColor("red");
      }
    });
  };

  const handleWrongFileFormatUpload = () => {
    setUploadPressed(false);
    setSelectedFile(null);
  };

  return (
    <div className="flex flex-col w-full h-screen items-center justify-center bg-grey-lighter">
      <label className="w-1/4 flex flex-col items-center px-4 py-6  mb-2 bg-white text-blue rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue hover:text-gray-400">
        {!uploadPressed ? (
          <>
            <UploadSvg />
            <span className="mt-2 font-medium font-sans">
              {selectedFile ? selectedFile.name : "Select a file"}
            </span>
            <input
              type="file"
              className="hidden"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={fileSelectedHandles}
            />
          </>
        ) : (
          <>
            <button
              type="button"
              className="flex flex-col items-center"
              disabled
            >
              <div
                className={`animate-ping absolute rounded-full bg-${alertColor}-400 w-4 h-4 mb-2`}
              ></div>
              <div
                className={`rounded-full bg-${alertColor}-500 w-4 h-4 mb-2`}
              ></div>
              <div className="font-semibold text-black hover:text-black">
                {message}
              </div>
              {selectedFile.type !==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                <button
                  className="my-5 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => handleWrongFileFormatUpload()}
                >
                  Back to upload
                </button>
              ) : null}
            </button>
          </>
        )}
      </label>
      {!uploadPressed ? (
        <TailwindCssButton onClick={fileUploadHandler}>
          Upload
        </TailwindCssButton>
      ) : null}
      {/* Former field used to put ngrok API link*/}
      {/* {showExhibitUrlInput ? (
        <form className="w-1/4 flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-blue hover:text-gray-400 justify-around">
          <div className="mx-2 w-full">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="url"
              type="text"
              placeholder="Replace with ngrok URL for NLP API"
              onChange={(event) => {
                setEXHIBIT_API_URL(event.target.value);
                props.setExhibitUrlPath(event.target.value);
              }}
            />
          </div>
          <button
            className="w-full text-white font-sans my-4 shadow-lg bg-green-400 font-medium px-4 py-2 rounded hover:bg-green-300 inline-block"
            type="button"
            onClick={() => uploadObjectToMachineLearningAPI()}
          >
            Find information on {selectedFile.name}
          </button>
        </form>
      ) : null} */}
      {showProcessFile ? (
        <div className="w-1/4">
          {findInformationBtnClicked ? (
            <div className="relative pt-1">
              <div className="overflow-hidden h-2 text-xs flex rounded bg-blue-200">
                <div
                  style={{ width: `${fakeProgress}%` }}
                  className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                ></div>
              </div>
            </div>
          ) : null}
          {findInformationBtnClicked ? null : (
            <button
              disabled={disbableButton}
              className="w-full text-white font-sans my-4 shadow-lg bg-green-400 font-medium px-4 py-2 rounded hover:bg-green-300 inline-block"
              type="button"
              onClick={() => uploadObjectToMachineLearningAPI()}
            >
              Find information on {selectedFile.name}
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};

const TailwindCssButton = (props) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="w-1/4 text-gray-900 font-sans my-4 shadow-lg bg-gray-400 font-medium px-4 py-2 rounded hover:bg-gray-300 inline-block"
    >
      {props.children}
    </button>
  );
};

export default UploadCard;
