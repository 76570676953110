const SearchBar = (props) => {
  return (
    <div className="m-4 flex flex-row">
      <input
        className="focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="search-box"
        type="text"
        placeholder="To be or not to be?"
        onChange={(event) => {
          props.setQuestion(event.target.value);
        }}
      />
      <button
        disabled={props.disabled}
        onClick={() => props.getAnswers()}
        className="flex items-center transform duration-300 hover:scale-105 mx-5 px-5 bg-yellow-300 rounded shadow text-gray-600 font-semibold"
      >
        Find
      </button>
    </div>
  );
};

export default SearchBar;
