import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import QA from "./pages/qa.page";
import Upload from "./pages/upload.page";
import { useState, useEffect } from "react";

const NLP_URL =
  process.env.NODE_ENV === "production"
    ? "https://nlp-server.bitstone.eu"
    : "https://nlp-server.bitstone.eu"; // replace with local dev server

const WEBSOCKET_URL =
  process.env.NODE_ENV === "production"
    ? "wss://nlp-server.bitstone.eu/initiate-pipeline"
    : "wss://nlp-server.bitstone.eu/initiate-pipeline"; // ws://127.0.0.1:8001/initiate-pipeline

const socket_client = new WebSocket(WEBSOCKET_URL);

const App = () => {
  const [EXHIBIT_URL_PATH, setExhibitUrlPath] = useState("");
  const [loadBrain, setLoadBrain] = useState(false);

  useEffect(() => {
    socket_client.onopen = (event) => {
      socket_client.send("Hello Server!");
    };
    console.log(socket_client)
  }, []);

  return (
    <div className="p-4">
      <Router>
        <div className="flex justify-center text-xl">
          <p className={loadBrain ? "animate-pulse": ""}>🧠</p> Exhibit Manager NLP
        </div>
        <div>
          <Switch>
            <Route exact path="/">
              <Upload setExhibitUrlPath={setExhibitUrlPath} socket={socket_client} />
            </Route>
            <Route path="/qa">
              <QA urlPath={NLP_URL} setLoadBrain={setLoadBrain}/>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
