const ExhibitInfoCard = (props) => {
  const { score, context, answer, footnotes } = props.info || {};

  if (typeof props.info === "undefined") {
    return (
      <div
        className="animate-pulse transform duration-300 hover:scale-1005 shadow m-4 border rounded p-2 mr-9 bg-green-400 bg-opacity-50"
      >
        <div className="py-2 flex flex-row content-evenly">
          <label className="w-40 h-10 bg-green-600 rounded"></label>
          <p className="w-full h-24 mx-2 bg-green-700 rounded">{context}</p>
        </div>
        <div className="py-2 flex flex-row content-evenly">
          <label className="w-40 h-10 bg-green-600 rounded"></label>
          <p className="w-full h-24 mx-2 bg-green-700 rounded">{context}</p>
        </div>
        <div className="py-2 flex flex-row content-evenly">
          <label className="w-40 h-10 bg-green-600 rounded"></label>
          <p className="w-full h-24 mx-2 bg-green-700 rounded">{context}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`transform duration-300 hover:scale-1005 shadow m-4 border rounded p-2 mr-9 bg-green-400 bg-opacity-${
          Math.ceil((score / 1.2) * 10) * 10
        }`}
      >
        {/* improve 9/12 to w-full */}
        <div className="py-2 flex flex-row content-evenly">
          <label className="w-40 font-bold">Reference Text: </label>
          <p className="w-9/12 mx-2">{context}</p>
        </div>
        <div className="py-2 flex flex-row content-evenly">
          <label className="w-40 font-bold">Proposed Answer: </label>
          <p className="w-9/12 mx-2">{answer}</p>
        </div>
        <div className="py-2 flex flex-row content-evenly">
          <label className="w-40 font-bold">Footnotes: </label>
          <div
            className={
              footnotes?.length > 0
                ? `w-9/12 mx-2 border border-gray-900 p-2 rounded`
                : `w-9/12 mx-2`
            }
          >
            {footnotes?.length > 0 ? (
              footnotes.map((footnote) => (
                <div className="flex flex-row">
                  <label className="px-3 font-semibold">{footnote.index}</label>
                  <p className="pb-2 italic text-sm">{footnote.text}</p>
                </div>
              ))
            ) : (
              <div className="font-semibold">No footnotes</div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ExhibitInfoCard;
