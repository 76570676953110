import UploadCard from "../components/upload/upload.component";



const Upload = (props) => {
  return (
    <>
      <UploadCard setExhibitUrlPath={props.setExhibitUrlPath} socket={props.socket}/>
    </>
  );
};
export default Upload;
